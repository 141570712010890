import React from 'react'
import cl from "./FormInputLighter.module.css"

function FormInputLighter({placeholder, title, name, necessarily, value, onChange, isValid}) {
  return (
    <div className={cl.formInputLighter}>
        <div className={cl.formInputLighter__title}>{title}{necessarily && <span>*</span>}</div>
        <input className={isValid ? cl.error : " "} type="text" name={name} placeholder={placeholder} value={value} onChange={(e) => onChange(e)}/>    
    </div>
  )
}

export default FormInputLighter