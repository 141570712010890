import React from 'react'
import cl from './MainPage.module.css'
import welcomeImg from '../../shared/assets/images/image 1.png'
import '../../shared/styles/main.css'
import AdvantageItem from '../../widgets/AdvantageItem/AdvantageItem'
import lightning from "../../shared/assets/svgIcons/lightning.svg"
import heart from "../../shared/assets/svgIcons/heart.svg"
import call from "../../shared/assets/svgIcons/call.svg"
import star from "../../shared/assets/svgIcons/star.svg"
import Map from '../../widgets/Map/MapComponent'


function MainPage() {
  return (
    <div>
        {/**  WELCOME **/}
        <section id='welcome'>
            <div className={cl.welcome}>
                <div className={cl.welcomeText__wrapper}>
                    <div className={cl.welcomeText}>
                        <h2 className={cl.welcomeText__title}>
                            АГРИКА - фермерское хозяйство
                        </h2>
                        <div className={cl.welcomeText__subtitle}>
                            Откройте для себя высококачественную сельскохозяйственную продукцию!
                        </div>  
                    </div>
                </div>
                <div className={cl.welcomeImg}>
                    <img src={welcomeImg} alt='sunflowers'/>
                </div>  
            </div>
        </section>

        <section id='about'>
            <div className='_container'>
                <h3 className={cl.section__title}>О компании</h3>
                <div className={cl.about__text}>
                    <div>Фермерское хозяйство «Агрика» образовано в 1992 году на территории Петровского района Саратовской области. Основное направление деятельности хозяйства - выращивание и переработка семян подсолнечника. В 1995 году был запущен цех по производству нерафинированного подсолнечного масла производительностью 15 т в сутки.</div>
                    <div>В 2000 году запущен цех по производству калиброванной семечки производительностью до 150 т в месяц. В 2005 году освоено производство жареной фасованной семечки под названием "Петушок" и "Петушок Золотой" производительностью до 50 т в месяц. Для строительства производственных и жилых зданий в хозяйстве построен цех по производству пенобетона.</div>
                </div>
                <div className={cl.about__important}>
                    <div className={cl.aboutImportant__text}>Подсолнечник, выращенный на полях фермерского хозяйства - экологически чистый продукт.</div>
                    <div className={cl.aboutImportant__number}>
                        <div>
                            <span className={cl.aboutImportantNumber__more}>более</span> <span className={cl.aboutImportantNumber__digits}>30</span>
                        </div>
                        <div>
                            лет <br/> опыта
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='advantages'>
            <div className='_container'>
                <h3 className={cl.section__title}>Преимущества</h3>
                <div className={cl.advantages__items}>
                    <AdvantageItem title="Мощность" description="Большая производственная мощность дает нам возможность выполнить Ваш заказ в короткие сроки" svg={lightning}/>
                    <AdvantageItem title="Любовь" description="Нам не важно, маленький магазинчик у вас или холдинг. В любом случае Вы достойны внимания и мы сделаем все, чтобы удовлетворить Ваши потребности" svg={heart}/>
                    <AdvantageItem title="Связь" description="Вы можете связаться с нами любым удобным для вас способом: будь это мессенджер, эл. почта или телефон" svg={call}/>
                    <AdvantageItem title="Качество" description="Контроль качества продукции на всех этапах производства. Качество без компромиссов" svg={star}/>
                </div>
            </div>
        </section>

        <section id='location'>
            <div className='_container'>
                <h3 className={cl.section__title}>Где мы находимся?</h3>
                <div className={cl.map}>
                    <Map/>
                </div>
            </div>
        </section>

    </div>
  )
}

export default MainPage