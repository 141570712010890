import len from '../../shared/assets/images/len.jpg'
import saflor from '../../shared/assets/images/saflor.jpg'
import devices from '../../shared/assets/images/devices.jpg'
import drying from '../../shared/assets/images/drying.jpg'
import photoSeparator from '../../shared/assets/images/photoSeparator.jpg'

export default class Data {
    static products = [
        {
          id: 1,
          title: "Лён коричневый",
          price: 60,
          description: "Завод-производитель на постоянной основе реализует ядро подсолнечника очень хорошего качества! Упаковка в полипропиленовые мешки по 35 кг,,25кг, в бумажные мешки по 25 кг.. Доставка в любую точку.",
          isPopular: true,
          count: 4000,
          isStock: true,
          images: [
            {
              id: 1,
              images: len
            },
          ]
        },
        {
          id: 2,
          title: "Сафлор",
          price: 25,
          description: "Завод-производитель на постоянной основе реализует ядро подсолнечника очень хорошего качества! Упаковка в полипропиленовые мешки по 35 кг,,25кг, в бумажные мешки по 25 кг.. Доставка в любую точку.",
          isPopular: true,
          count: 4000,
          isStock: true,
          images: [
            {
              id: 25,
              images: saflor
            },
          ]
        },
      ];

    static services = [
        {
          id: 3,
          title: "Подработка продукции на фотосепараторе",
          price: 1,
          images: photoSeparator,
          description: "Английское оборудование. Современные технологии позволяют добится идеальной чистоты продукции. Очистка до 99.9%"
        },
        {
          id: 4,
          title: "Сушка",
          price: 0,
          images: drying,
          description: "Сушка зерна — один из самых эффективных приемов подготовки зерна к длительному хранению Полная автоматизация и работа на твердом топливе позволяет добится минимальных затрат при сушке."
        },
        {
          id: 5,
          title: "Подсолнечник",
          price: 1,
          images: devices,
          description: "Послеуборочная обработка зерна является одним из важных этапов в процессе зернопроизводства. Калибровка подсолнечника повышает рентабельность выращевания, за счет цены на крупную семечку. Урожай во многом зависит от качества посевного материала. Правильная подготовка семян обеспечивает высокую всхожесть."
        },
    ];

    static purchases = [
        {
          id: 1,
          title: "нужны семена льна",
          description: "Хотим купить 10 тон льна"
        }
    ];
}
