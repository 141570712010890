import React from 'react'
import cl from "./Footer.module.css"
import NavItem from '../../shared/modules/NavItem/NavItem'
import { useLocation } from 'react-router-dom'


function Footer({className}) {
    const location = useLocation()
  return (
    <div className={`${cl.footer} ${className}`}>
        <div className={cl.footer__title}>
            Агрика
        </div>
        <div className={cl.footer__info}>
            <div className={cl.footerInfo__nav}>
                <NavItem path="/" isActive={location.pathname === "/"}>О компании</NavItem>
                <NavItem path="/products" isActive={location.pathname === "/products"}>Продукция</NavItem>
                <NavItem path="/partnership" isActive={location.pathname === "/partnership"}>Партнерство</NavItem>
                <NavItem path="/contacts" isActive={location.pathname === "/contacts"}>Контакты</NavItem>
            </div>
            <div className={cl.footerInfo__geo}>
                <div className={cl.footerInfo__title}>
                    Адрес
                </div>
                <div className={cl.footerInfoGeo__subtitle}>
                    412531, Саратовская область,<br/>
                    Петровский район, <br/>
                    д. Гудошниково пром. зона <br/>
                    фермерское хозяйство "Агрика"
                </div>
            </div>
            <div className={cl.footerInfo__contacts}>
                <div className={cl.footerInfo__title}>
                    Контактная информация
                </div>
                <div className={cl.footerInfoContacts__subtitle}>
                    agrika0@mail.ru<br/>
                    +7-960-346-89-98 Александр<br/>
                    +7-905-325-92-62 Владимир<br/>
                </div>
            </div>
        </div>
        <div className={cl.footer__copy}>
            Авторское право© 1992-2017 - Все права защищены- Агрика
        </div>
    </div>
  )
}

export default Footer