import React from 'react'
import cl from './BtnChangeLanguage.module.css'

function BtnChangeLanguage() {
  return (
    <div className={cl.changeLanguage}>
        <div className={cl.changeLanguage__text}>Language</div>
        <div className={cl.changeLanguage__toggle}>
            <select className={cl.changeLanguageToggle__select}>
                <option value="RU">ru</option>
                <option value="ENG">eng</option>
                <option value="DE">de</option>
            </select>
        </div>
    </div>
  )
}

export default BtnChangeLanguage