import React from 'react'
import cl from "./BtnCircle.module.css"

function BtnCircle({image, alt, onClick}) {
  return (
    <div className={cl.btnCircle} onClick={() => onClick()}>
        <img src={image} alt={alt}/>
    </div>
  )
}

export default BtnCircle