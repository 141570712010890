import React from 'react'
import cl from "./PartnershipPage.module.css"
import PartnershipItem from '../../widgets/PartnershipItem/PartnershipItem'
import BtnSubmit from '../../shared/modules/BtnSubmit/BtnSubmit'
import empty from "../../shared/assets/svgIcons/empty.svg"

function PartnershipPage({className, purchaseItems, jobsItems, handleOpenModal}) {
  return (
    <div className={`${cl.partnershipPage} ${className}`}>
        <section className={cl.partnershipPage__purchase}>
            <h3 className={cl.purchase__title}>Закупка</h3>
            {
                purchaseItems && purchaseItems.length > 0 && (
                    <div className={cl.purchase__items}>
                        {
                            Array.isArray(purchaseItems) && purchaseItems.map((item) => (
                                <PartnershipItem 
                                    key={item.id} 
                                    title={item.title} 
                                    description={item.description} 
                                    handleOpenModal={handleOpenModal}
                                />
                            ))
                        }
                    </div>  
                )
            }
            
            <BtnSubmit className={cl.offer__btn} onClick={handleOpenModal}>Предложить свой товар</BtnSubmit>
        </section>
        <section className={cl.partnershipPage__jobs}>
            <h3 className={cl.jobs__title}>Вакансии</h3>
            {
                jobsItems && jobsItems.length > 0 
                ? 
                <div className={cl.jobs__items}>
                    {
                        Array.isArray(jobsItems) && jobsItems?.map((item) => (
                            <PartnershipItem key={item.id} title={item.title} description={item.description}/>
                        ))
                    }
                </div>
                :
                <div className={cl.jobsItems__empty}>
                    <img src={empty} alt="Пустота" className={cl.jobsItemsEmpty__image}/>
                    <div className={cl.jobsItemsEmpty__text}>Свободных вакансий пока что нет</div>
                </div>
            }
            
        </section>
    </div>
  )
}

export default PartnershipPage