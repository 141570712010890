import React from 'react'
import cl from "./ProductItem.module.css"
import popular from "../../shared/assets/svgIcons/popular.svg"
import shop from "../../shared/assets/svgIcons/mingcute_basket-line.svg"
import check from "../../shared/assets/svgIcons/check.svg"
import { Link } from 'react-router-dom'

function ProductItem({item, cart, addCart, deleteCart}) {
    
    const { title, price, images, isPopular, id} = item;
    const { cartItems } = cart;

    const isFound = cartItems.some(item => {
        if (item.id === id){
            return true;
        }
        return false;
    })

  return (
    <div className={cl.productItem}>
        <Link to={`/products/${id}`}><img className={cl.productItem__img} src={images[0].images} alt={title} /></Link>
        <div className={cl.productItem__info}>
            <div>
                <div className={cl.productItemInfo__title}>{title}</div>
                <div className={cl.productItemInfo__price}>{price} ₽ / кг</div>
            </div>
            {
                !isFound
                ? 
                    <div className={cl.productItem__shopBtn} onClick={() => addCart(id)}>
                        <img src={shop} alt="shopBtn" />
                    </div>
                :
                    <div className={cl.productItem__checkBtn} onClick={() => deleteCart(id)}>
                        <img src={check} alt="checkCart" />
                    </div>
            }              
            
        </div>
        {isPopular 
         ? (
            <div className={cl.popular}>
                <img src={popular} alt='popular'/>
            </div>
         )
         : (
            <div></div>
         )
        }
        
    </div>
  )
}

export default ProductItem