import React from 'react'
import cl from "./FormInput.module.css"

function FormInput({title, necessarily, value, setValue, isValid}) {
  return (
    <div className={cl.formInput}>
        <div className={cl.formInput__title}>{title}{necessarily && <span>*</span>}</div>
        <input className={`${cl.formInput__input} ${isValid ? cl.error : ' '}`} type="text" onChange={(e) => setValue(e.target.value)} value={value}/>
    </div>
  )
}

export default FormInput