import React, { useState } from 'react'
import cl from "./ImgSlider.module.css"
import back from "../../shared/assets/svgIcons/back.svg"
import BtnCircle from '../../shared/modules/BtnCircle/BtnCircle'

function ImgSlider({title, imgFiles}) {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    function onNextImg(){
        if (currentImageIndex < imgFiles.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    }

    function onPrevImg(){
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    }

  return (
    <div className={cl.imgSlider}>
        <div className={cl.imgSliderContainer}>
            <img 
                src={imgFiles[currentImageIndex].images} 
                alt={title} 
                className={cl.imgSliderContainer__image}
            />
        </div>
        {
            imgFiles.length > 1 && (
                <div className={cl.imgSlider__btns}>
                    <BtnCircle image={back} alt="btnBack" onClick={onPrevImg}/>
                    <BtnCircle image={back} alt="btnNext" onClick={onNextImg}/>
                </div>
            )
        }
    </div>
  )
}

export default ImgSlider