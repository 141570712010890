import React from 'react'
import cl from "./BtnReply.module.css"

function BtnReply({children, className, onClick}) {
  return (
    <div className={`${cl.btnReply} ${className}`} onClick={() => onClick()}>
        <div className={cl.btnReply__text}>{children}</div>
    </div>
  )
}

export default BtnReply