import React from 'react'
import cl from "./AdvantageItem.module.css"


function AdvantageItem({title, description, svg}) {
  return (
    <div className={cl.advantageItem}>
        <div className={cl.advantageItem__title}>
            <img className={cl.advantageItemTitle__img} src={svg} alt="advantageImg"/>
            <div className={cl.advantageItemTitle__text}>
                {title}
            </div>
        </div>
        <div className={cl.advantageItem__description}>
            {description}
        </div>
    </div>
  )
}

export default AdvantageItem