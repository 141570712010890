import React, { useEffect, useState } from 'react';
import cl from "./CartPage.module.css";
import FormComponent from '../../widgets/FormComponent/FormComponent';
import TotalPriceComponent from '../../widgets/TotalPriceComponent/TotalPriceComponent';
import CartItem from '../../widgets/CartItem/CartItem';
import notFound from '../../shared/assets/svgIcons/search-error.svg';
import SendServer from '../../api/Service';
import MockServer from '../../api/mocks/Mocks';

function CartPage({ className, cart }) {

  const server = process.env.REACT_APP_MODE === "production" ? SendServer : MockServer;

  const { cartItems = [], setCartItems } = cart;
  const [totalPrice, setTotalPrice] = useState(0);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Подсчет общей стоимости корзины на основе cartCount или 1, если cartCount отсутствует
    setTotalPrice(cartItems.reduce((prev, curr) => prev + (curr.price * (curr.cartCount || 1)), 0));
  }, [cartItems]);

  //* Функция для отправки корзины на сервер
  const sendCart = async () => {
    const cartId = await server.getCartId();
    const cartData = cartItems.map(item => ({
      cartId: cartId + 1,
      phone: phone,
      email: email,
      title: item.title,
      count: item.cartCount || 1,
      price: item.price || 0,
    }))

    try{
      const response = await server.postCart(cartData);
      if (response.ok){
        setMessage("Ваша корзина успешно отправлена");
        setCartItems([]);
        console.log("Корзина успешно отправлена", cartData);
      } else{
        setError("Произошла ошибка при отправке. Попробуйте ещё раз!");
        console.log("Ошибка отправки корзины", response.error);
      }
    } catch (error) {
      setError('Произошла ошибка при отправке. Попробуйте ещё раз!');
      console.log("Ошибка отправки корзины", error);
    }
  }

  //* Функция для удаления товара из корзины
  const deleteCartItem = (id) => {
    console.log('DELETE!!!', id);
    setCartItems((cart) => cart.filter((product) => id !== product.id));
  };


  //* Функция для увеличения кол-во товара
  const increase = (id) => {
    console.log("INCREASE!!!", id);
    setCartItems((cart) => cart.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          cartCount: (product.cartCount || 1) + 1,
        };
      }
      return product;
    }));
  };

  //* Функция для уменьшения кол-во товара
  const decrease = (id) => {
    console.log("DECREASE!!!", id);
    setCartItems((cart) => cart.map((product) => {
      if (product.id === id) {
        const newCount = (product.cartCount || 1) - 1 > 0 ? (product.cartCount || 1) - 1 : 1;
        return {
          ...product,
          cartCount: newCount,
        };
      }
      return product;
    }));
  };

  //* Изменение кол-во товара
  const changeValue = (id, value) => {
    // Изменение cartCount на указанное значение
    setCartItems((cart) => cart.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          cartCount: value,
        };
      }
      return product;
    }));
  };

  return (
    <section id={cl.cart} className={className}>
      <h3 className={cl.cart__title}>Корзина</h3>
      <div className={cl.cart}>
        <div className={cl.cart__items}>
          {
            cartItems && cartItems.length > 0
              ? (
                cartItems.map((cartItem) => (
                  <CartItem
                    key={cartItem.id}
                    item={cartItem}
                    deleteCartItem={deleteCartItem}
                    increase={increase}
                    decrease={decrease}
                    changeValue={changeValue}
                  />
                ))
              )
              : 
              <div className={cl.noCart}>
                <img className={cl.noCart__img} src={notFound} alt='notFound' />
                <div className={cl.noCart__text}>Нет товаров</div>
              </div>
          }
        </div>
        <div className={cl.cart__result}>
          <TotalPriceComponent totalPrice={totalPrice} />
          <FormComponent 
            isDisabled={cartItems.length > 0 ? false : true} 
            sendCart={sendCart} 
            phone={phone} 
            setPhone={setPhone} 
            email={email} 
            setEmail={setEmail}
          />
          {
            message && (
              <div className={cl.cartResult_message}>{message}</div>
            )
          }
          {
            error && (
              <div className={cl.cartResult_error}>{error}</div>
            )
          }
        </div>
      </div>
    </section>
  );
}

export default CartPage;
