import React from 'react'
import cl from "./PartnershipItem.module.css"
import BtnReply from '../../shared/modules/BtnReply/BtnReply'

function PartnershipItem({title, description, handleOpenModal}) {
  return (
    <div className={cl.partnershipItem}>
        <div className={cl.partnershipItem__title}>{title}</div>
        <div className={cl.partnershipItem__description}>{description}</div>
        <BtnReply className={cl.partnershipItem__btn} onClick={() => handleOpenModal(
          {
            title: title,
            description: description,
            phone: '',
            email: '',
          }
        )}>Откликнуться</BtnReply>
    </div>
  )
}

export default PartnershipItem