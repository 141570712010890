import React from 'react'
import cl from "./ProductView.module.css"
import BtnSubmit from '../../shared/modules/BtnSubmit/BtnSubmit'
import back from "../../shared/assets/svgIcons/back.svg"
import { Link, useParams } from 'react-router-dom'
import shop from "../../shared/assets/svgIcons/mingcute_basket-line.svg"
import check from "../../shared/assets/svgIcons/check.svg"
import ImgSlider from '../../widgets/ImgSlider/ImgSlider'

function ProductView({className, items, cart}) {
    const { id } = useParams();
    const item = items.find(i => i.id === Number(id));
    const {cartItems, setCartItems} = cart;
    
    const { title, price, description, images, isPopular} = item;
    
    const isFound = cartItems.some(item => {
        if (item.id === Number(id)){
            return true;
        }
        return false;
    })

    const addCart = () => {
        setCartItems(prevItems => [...(prevItems || []), item]);
        console.log("ДОБАВЛЕН ТОВАР!", item);
    }

    const deleteCart = () => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== Number(id)));
        console.log("УДАЛЕН ТОВАР!", id);
    }

  return (
    <div className={`${cl.productView} ${className}`}>
        <Link to="/products">
            <div className={cl.productView__backBtn}>
                <img src={back} alt="backBtn" />
                <div className={cl.productViewBackBtn__text}>Продукция</div>
            </div>
        </Link>
        <div className={cl.productView__info}>
            <div className={cl.productViewInfo__left}>
                <div className={cl.left__image}>
                    <ImgSlider imgFiles={images} title={title}/>
                    {
                        isPopular && <div className={cl.left__popular}>Выбор покупателей</div>
                    }
                </div>
                <div className={cl.left__text}>Количество товара можно выбрать при оформлении заказа в корзине</div>
                {
                    isFound
                    ? <BtnSubmit height="46px" className={cl.btnShop} image={check} onClick={deleteCart}>Добавлен</BtnSubmit>
                    : <BtnSubmit height="46px" className={cl.btnShop} image={shop} onClick={addCart}>В корзину</BtnSubmit>
                }
            </div>
            <div className={cl.productViewInfo__right}>
                <div>
                    {
                        isPopular && <div className={cl.right__popular}>Выбор покупателей</div>
                    }
                    <div className={cl.right__title}>{title}</div>
                    <div className={cl.right__price}>{price} ₽ / кг</div>
                </div>
                <div>
                    <div className={cl.right__description}>Описание</div>
                    <div className={cl.productViewInfo__characteristics}>{description}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductView