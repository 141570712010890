import React from 'react'
import cl from './ModalWindow.module.css'

function ModalWindow({children, visible, handleCloseModal}) {

  const rootClasses = [cl.modalWindow]
  if(visible){
    rootClasses.push(cl.active);
  }

  return (
    <div className={rootClasses.join(' ')} onClick={() => handleCloseModal()}>
        <div className={cl.modalWindow__content} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
    </div>
  )
}

export default ModalWindow