import React from 'react'
import cl from "./BtnSubmit.module.css"

function BtnSubmit({children, isDisabled, className, image, height, onClick}) {

  const rootClasses = [cl.btnSubmit]

  if (isDisabled) {
    rootClasses.push(cl.disabled)
  }

  if (image) {
    rootClasses.push(cl.btnSubmit__gap)
  }

  return (
    <div style={{height: height}} className={`${rootClasses.join(' ')} ${className}`} onClick={() => onClick()}>
        {
          image
          ? <img className={cl.btnSubmit__image} src={image} alt={children} />
          : <div/>
        }
        <div className={cl.btnSubmit__text}>{children}</div>
    </div>
  )
}

export default BtnSubmit