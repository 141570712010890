import React, { useState } from 'react'
import cl from "./FormComponent.module.css"
import FormInput from '../../shared/modules/FormInput/FormInput'
import BtnSubmit from '../../shared/modules/BtnSubmit/BtnSubmit'

function FormComponent({isDisabled, sendCart, phone, email, setPhone, setEmail}) {

  const [phoneError, setPhoneError] = useState(false);
  const [mailError, setMailError] = useState(false);

  const validatePhone = (phone) => {
    const phonePattern = /^((8|\+7)[\d\- ]?)?(\(?\d{3}\)?[\d\- ]?)?[\d\- ]{7,10}$/;
    return phonePattern.test(phone);
  }

  const validateEmail = (email) => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }

  const handleSendCart = () => {
    let isValid = true;

    if (!validatePhone(phone) || !phone) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    if(email && !validateEmail(email)){
      setMailError(true);
      isValid = false;
    } else {
      setMailError(false);
    }

    if(isValid){
      sendCart();
    }

  }
  
  return (
    <div className={cl.form}>
        <div className={cl.form__text}>Для оформления заказа укажите контактную информацию, чтобы мы могли с вами связаться</div>
        <FormInput title="Телефон" necessarily={true} value={phone} setValue={setPhone} isValid={phoneError}/>
        <FormInput title="Почта" value={email} setValue={setEmail} isValid={mailError}/>
        <BtnSubmit isDisabled={isDisabled} className={cl.form__btn} onClick={handleSendCart}>Оформить</BtnSubmit>
    </div>
  )
}

export default FormComponent