import React from 'react';
import cl from "./CartItem.module.css";
import trash from "../../shared/assets/svgIcons/trash.svg";

function CartItem({ item, deleteCartItem, increase, decrease, changeValue }) {
  const { title, images, id, price, cartCount = 1 } = item;
  const totalPrice = cartCount * price;

  return (
    <div className={cl.cartItem}>
      <div className={cl.cartItem__img}>
        <img src={images[0].images ? images[0].images : images} alt={title} />
      </div>
      <div className={cl.cartItem__info}>
        <div className={cl.cartItemInfo}>
          <div className={cl.cartItemInfo__title}>{title}</div>
          <div className={cl.cartItemInfo__price}>{totalPrice} ₽</div>
        </div>
        <div className={cl.cartItem__count}>
          <button onClick={() => decrease(id)}>-</button>
          <input
            type='number'
            min="1"
            value={cartCount}
            className={cl.cartItemCount__input}
            onChange={(e) => changeValue(id, +e.target.value)}
          />
          <button onClick={() => increase(id)}>+</button>
        </div>
      </div>
      <div className={cl.cartItem__trash} onClick={() => deleteCartItem(id)}>
        <img src={trash} alt='deleteBtn'/>
      </div>
    </div>
  );
}

export default CartItem;
