import React from 'react'
import cl from "./Header.module.css"

import NavItem from '../../shared/modules/NavItem/NavItem'
import BtnChangeLanguage from '../../shared/modules/BtnChangeLanguage/BtnChangeLanguage'
import BtnShop from '../../shared/modules/BtnShop/BtnShop'
import { useLocation } from 'react-router-dom'

function Header({className}) {
  const location = useLocation();

  return (
    <div className={`${cl.wrapper} ${className}`}>
        <div className={cl.logo}>Агрика</div>
        <div className={cl.navAndBtns}>
          <nav className={cl.nav}>
            <NavItem path="/" isActive={location.pathname === "/"}>О компании</NavItem>
            <NavItem path="/products" isActive={location.pathname === "/products"}>Продукция</NavItem>
            <NavItem path="/partnership" isActive={location.pathname === "/partnership"}>Партнерство</NavItem>
            <NavItem path="/contacts" isActive={location.pathname === "/contacts"}>Контакты</NavItem>
          </nav>

          <div className={cl.btns}>
            <BtnChangeLanguage/>
            <BtnShop>Корзина</BtnShop>
          </div>
        </div> 
    </div>
  )
}

export default Header