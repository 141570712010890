import React from 'react'
import cl from "./TotalPriceComponent.module.css"

function TotalPriceComponent({totalPrice}) {
  return (
    <div className={cl.totalPrice}>
        <div className={cl.totalPrice__title}>Итоговая сумма</div>
        <div className={cl.totalPrice__price}>{totalPrice > 0 ? `${totalPrice} ₽` : "–"}</div>
    </div>
  )
}

export default TotalPriceComponent