import React, { useState } from 'react'
import cl from './ModalOffer.module.css'
import FormInputLighter from '../../shared/modules/FormInputLighter/FormInputLighter'
import BtnReply from '../../shared/modules/BtnReply/BtnReply'
import FormTextarea from '../../shared/modules/FormTextarea/FormTextarea'

function ModalOffer({offer, setOffer, onSubmit}) {

  const [phoneError, setPhoneError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOffer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validatePhone = (phone) => {
    const phonePattern = /^((8|\+7)[\d\- ]?)?(\(?\d{3}\)?[\d\- ]?)?[\d\- ]{7,10}$/;
    return phonePattern.test(phone);
  }

  const validateEmail = (email) => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }

  const handleOnSubmit = () => {
    let isValid = true;

    if(!validatePhone(offer.phone) || !offer.phone){
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    if(!offer.title){
      setTitleError(true);
      isValid = false;
    } else {
      setTitleError(false);
    }

    if(offer.email && !validateEmail(offer.email)){
      setMailError(true);
      isValid = false;
    } else {
      setMailError(false);
    }

    if(isValid){
      onSubmit();
    }
  }

  return (
    <div className={cl.modalOffer}>   
      <FormInputLighter title="Продукт" name="title" necessarily={true} value={offer.title} onChange={handleChange} isValid={titleError}/>
      <FormTextarea title="Описание" name="description" value={offer.description} onChange={handleChange}/>
      <FormInputLighter 
        title="Контактный телефон" 
        name="phone"
        placeholder="Введите телефон" 
        necessarily={true} 
        value={offer.phone} 
        onChange={handleChange}
        isValid={phoneError}
      />
      <FormInputLighter title="Почта" name="email" placeholder="Введите email" value={offer.email} onChange={handleChange} isValid={mailError}/>
      <div className={cl.modalOffer__center}>
        <BtnReply className={cl.modalOffer__btn} onClick={handleOnSubmit}>Отправить</BtnReply>
      </div>
    </div>
  )
}

export default ModalOffer