import React from 'react'
import cl from "./ProductsPage.module.css"
import { Link, useLocation } from 'react-router-dom'
import ProductItem from '../../widgets/ProductItem/ProductItem'

import ServiceItem from '../../widgets/ServiceItem/ServiceItem'

function ProductsPage({className, isProducts, items, cart}) {
    const location = useLocation();
    const {setCartItems} = cart;

    const addCart = (id) => {
      console.log("ДОБАВЛЕН ТОВАР");
      const newItem = items.filter((item) => item.id === id);
      console.log(newItem[0]);
      setCartItems(prevItems => [...(prevItems || []), newItem[0]]);
    }

    const deleteCart = (id) => {
      console.log("ТОВАР БЫЛ УБРАН ИЗ КОРЗИНЫ!");
      setCartItems(prevItems => prevItems.filter(item => item.id !== id));
    }

  return (
    <div className={`${cl.productPage} ${className}`}>
        <div className={cl.productMenu}>
            <Link to="/products" className={`${cl.productMenu__item} ${location.pathname === "/products" ? cl.active : ''}`}>Товары</Link>
            <Link to="/services" className={`${cl.productMenu__item} ${location.pathname === "/services" ? cl.active : ''}`}>Услуги</Link>
        </div>
        <div className={cl.productWindow}>
            {isProducts 
            ? (
             <div className={cl.productWindow__products}>
              {
                items && Array.isArray(items) && items.map((item) => (
                  <ProductItem 
                    key={item.id} 
                    item={item}
                    cart={cart} 
                    addCart={addCart}
                    deleteCart={deleteCart}
                  />
                ))
              }
             </div> 
            )
            : (
            <div className={cl.productWindow__services}>
              {
                items && Array.isArray(items) && items.map((item) => (
                  <ServiceItem 
                    key={item.id} 
                    item={item}
                    cart={cart}
                    addCart={addCart}
                    deleteCart={deleteCart}
                  />
                ))
              }
            </div>
            )}
        </div>
    </div>
  )
}

export default ProductsPage