import React from 'react'
import cl from './SocialBtn.module.css'

function SocialBtn({icon, name, link}) {
  return (
    <a href={link} className={cl.socialBtn}>
      <img src={icon} alt={name} />
    </a>
  )
}

export default SocialBtn