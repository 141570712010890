import React from 'react'
import cl from "./ServiceItem.module.css"
import shop from "../../shared/assets/svgIcons/mingcute_basket-line.svg"
import check from "../../shared/assets/svgIcons/check.svg"

function ServiceItem({item, cart, addCart, deleteCart}) {

  const {title, price, description, images, id} = item;
  const {cartItems} = cart;

  const isFound = cartItems.some(item => {
    if (item.id === id){
        return true;
    }
    return false;
  })

  return (
    <div className={cl.serviceItem}>   
        <div className={cl.serviceItem__img}>
            <img src={images} alt={title}/>
        </div>
        <div className={cl.serviceItem__info}>
            <div className={cl.serviceItemInfo__title}>{title}</div>
            <div className={cl.serviceItemInfo__price}>
                {
                    price !== 0
                    ? `${price} ₽ / кг` 
                    : 'стоимость услуги зависит от влажности сырья'
                } 
            </div>
            <div className={cl.serviceItemInfo__description}>{description}</div>
        </div>
        {
                !isFound
                ? 
                    <div className={cl.serviceItem__shopBtn} onClick={() => addCart(id)}>
                        <img src={shop} alt="shopBtn" />
                    </div>
                :
                    <div className={cl.serviceItem__checkBtn} onClick={() => deleteCart(id)}>
                        <img src={check} alt="checkCart" />
                    </div>
            } 
    </div>
  )
}

export default ServiceItem