import React from 'react'
import cl from './FormTextarea.module.css'

function FormTextarea({title, necessarily, name, value, onChange}) {
  return (
    <div className={cl.formTextarea}>
        <div className={cl.formTextarea__title}>{title}{necessarily && <span>*</span>}</div>
        <textarea name={name} value={value} onChange={(e) => onChange(e)}/>
    </div>
  )
}

export default FormTextarea