import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from '../pages/MainPage/MainPage';
import '../shared/styles/main.css'
import Footer from '../widgets/Footer/Footer';
import Header from '../widgets/Header/Header';
import ContactsPage from '../pages/ContactsPage/ContactsPage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import CartPage from '../pages/CartPage/CartPage';
import { useCallback, useEffect, useRef, useState } from 'react';

import ProductView from '../pages/ProductView/ProductView';
import PartnershipPage from '../pages/PartnershipPage/PartnershipPage';
import ModalOffer from '../widgets/ModalOffer/ModalOffer';
import ModalWindow from '../features/ModalWindow/ModalWindow'
import SendServer from '../api/Service';
import MockServer from '../api/mocks/Mocks';
import HeaderMobile from '../widgets/HeaderMobile/HeaderMobile';

function App() {
  
  const server = process.env.REACT_APP_MODE === "production" ? SendServer : MockServer;

  const windowWidth = useRef(window.innerWidth);

  const [isModalOffer, setIsModalOffer] = useState(false);
  const handleOpenModal = (offer) => {
    setIsModalOffer(true);
    if(offer){
      setOffer(offer);
    }
    // console.log(isModalOffer);
  }

  const handleCloseModal = () => {
    setIsModalOffer(false);
    setOffer(
      {
        title: '',
        description: '',
        phone: '',
        email: ''
      }
    );
  }

  const [products, setProducts] = useState();
  const [services, setServices] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState();
  const [jobsItems, setJobsItems] = useState();
  const [offer, setOffer] = useState(
    {
      title: '',
      description: '',
      phone: '',
      email: ''
    }
  )

  const getItems = useCallback(async () => {
    const responseProducts = await server.getProducts();
    const responseServices = await server.getServices();
    setProducts(responseProducts);
    setServices(responseServices)
  }, [server]);

  const getPartnershipItems = useCallback(async () => {
    const responsePurchases = await server.getPurchases();
    const responseEmployees = await server.getEmployees();
    setPurchaseItems(responsePurchases);
    setJobsItems(responseEmployees);
  }, [server]);

  const createOffer = async () => {
    // console.log("CREATE", offer);
    server.postOffer(offer);
    setIsModalOffer(false);
    setOffer(
      {
        title: '',
        description: '',
        phone: '',
        email: ''
      }
    )
  }

  useEffect(() => {
    getItems();
    getPartnershipItems();
  }, [getItems, getPartnershipItems])

  return (

    <div>
      <BrowserRouter>
        {
          windowWidth.current > 1000
          ?
          <Header className="_container"/>
          :
          <HeaderMobile/>
        }
        <ModalWindow visible={isModalOffer} handleCloseModal={handleCloseModal}>
          <ModalOffer onSubmit={createOffer} setOffer={setOffer} offer={offer}/>
        </ModalWindow>        
        <Routes>
          <Route path='*' element={<Navigate to="/" replace/>}/>
          <Route path="/" element={<MainPage/>}/>
          <Route path="/contacts" element={<ContactsPage className="_container"/>}/>
          <Route path="/partnership" element={
            <PartnershipPage 
              className="_container" 
              purchaseItems={purchaseItems} 
              jobsItems={jobsItems}
              handleOpenModal={handleOpenModal}
            />
          }/>
          <Route path="/products" element={
            <ProductsPage 
              className="_container" 
              isProducts={true} 
              items={products} 
              cart={{cartItems, setCartItems}}
            />
          }/>
          <Route path="/services" element={
            <ProductsPage 
              className="_container" 
              isProducts={false} 
              items={services} 
              cart={{cartItems, setCartItems}}
            />
          }/>
          <Route path="/cart" element={
            <CartPage
              className="_container" 
              cart={{cartItems, setCartItems}} 
            />
          }/>
          <Route path="/products/:id" element={<ProductView className="_container" items={products} cart={{cartItems, setCartItems}}/>}/>
        </Routes>
        <Footer className="_container"/>
      </BrowserRouter>
    </div>
 
  );
}

export default App;
