const sendMessage = async (message) => {
    try {
        const url = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_TOKEN}/sendMessage`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chat_id: process.env.REACT_APP_CHAT_ID,
                text: message,
            }),
        });

        return response;
    } catch (error) {
        // console.error("Error:", error);
        return {ok: false};
    }
};

export default sendMessage;
