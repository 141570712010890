import React from 'react';
import cl from "./MapComponent.module.css";
import { YMaps, Map, FullscreenControl, TypeSelector, ZoomControl, Placemark} from "@pbe/react-yandex-maps";

const center = [52.28966989872114, 45.254943569221496];
const apiKey = process.env.REACT_APP_YMAPS_API_KEY;

function MapComponent() {
    return (
        <YMaps query={{lang: 'en_RU', apikey: apiKey}}>
            <Map 
              className={cl.map} 
              defaultState={{center: center, zoom: 15}}
            > 
              <FullscreenControl/>
              <TypeSelector options={{ float: "right"}} defaultMapTypes={['yandex#satellite', 'yandex#map']}/>
              <ZoomControl options={{ float: "right" }} />

              <Placemark geometry={center} properties={{iconCaption: "АГРИКА"}} options={{iconColor: "#4C6629"}} />
            </Map>
        </YMaps>
    );
}

export default MapComponent;
