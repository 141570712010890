import React, { useState } from 'react'
import cl from './HeaderMobile.module.css'
import shop from '../../shared/assets/svgIcons/mingcute_basket-line.svg'
import { Link, useLocation } from 'react-router-dom'

function HeaderMobile() {
    const location = useLocation();
    const [isMenuOpen, setIsOpenMenu] = useState(false);
    
    const handleMenuToggle = () => {
        setIsOpenMenu(prevState => !prevState);
    }

    const handleLinkClick = () => {
        setIsOpenMenu(false);
    }

  return (
    <div className={cl.headerMobile}>
        <nav className={cl.burgerMenu}>
            <div className={cl.burgerMenu_container}>
                <input 
                    className={cl.burgerMenu__checkbox} 
                    type="checkbox" 
                    name="" 
                    id="" 
                    checked={isMenuOpen}
                    onChange={handleMenuToggle}
                />
                <div className={cl.burgerMenu__lines} onClick={handleMenuToggle}>
                    <span className={`${cl.lines_line} ${cl.line1}`}></span>
                    <span className={`${cl.lines_line} ${cl.line2}`}></span>
                    <span className={`${cl.lines_line} ${cl.line3}`}></span>
                </div> 
                <ul className={cl.burgerMenu__items}>
                    <Link to="/" onClick={handleLinkClick}><li className={cl.burgerMenu_item}>О компании</li></Link>
                    <Link to="/products" onClick={handleLinkClick}><li className={cl.burgerMenu_item}>Продукция</li></Link>
                    <Link to="/partnership" onClick={handleLinkClick}><li className={cl.burgerMenu_item}>Партнерство</li></Link>
                    <Link to="/contacts" onClick={handleLinkClick}><li className={cl.burgerMenu_item}>Контакты</li></Link>
                </ul> 
            </div>              
        </nav>
        <div className={`${cl.headerMobile__title} ${isMenuOpen ? cl.open : ""}`}>Агрика</div>
        <div style={{zIndex: 4}} className={location.pathname === "/cart" ? cl.active : "" }>
            <Link to="/cart"><img className={`${cl.headerMobile__shopBtn} ${isMenuOpen ? cl.open : ""}`} src={shop} alt="shop" /></Link>
        </div>
    </div>
  )
}

export default HeaderMobile