import React from 'react'
import cl from './NavItem.module.css'
import { Link } from 'react-router-dom'

function NavItem({children, path, isActive}) {

  const rootClasses = [cl.navItem]

  if(isActive){
    rootClasses.push(cl.active)
  }

  return (
    <Link to={path} className={rootClasses.join(' ')}>{children}</Link>
  )
}

export default NavItem