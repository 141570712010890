import Data from "./data";
import sendMessage from '../tg_bot'

export default class MockServer{
    
    //* Функция для получения всех продукций
    static getProducts(){
        return Data.products;
    }

    //* Функция для получения всех услуг
    static getServices(){
        return Data.services;
    }

    //* Функция для получения всех работников
    static getEmployees(){
        return null;
    }

    //* Функция для получения всех закупок
    static getPurchases(){
        return Data.purchases;
    }

    //* Функция для создания заказа
    static async postCart(cartItems){
        if(cartItems.length === 0){
            return;
        }

        const { cartId = 'нет', phone = 'Не указано', email = 'Не указано'} = cartItems[0];

        const messages = cartItems.map(item => {
            const title = item.title || 'Неизвестно';
            const count = item.count || 1;
            const price = item.price || 0;

            const message = `Продукт: ${title}\nКоличество: ${count} кг\nЦена за кг: ${price}₽\n`;
            return { message, price: price * count };
        })

        const totalCost = messages.reduce((acc, curr) => acc + curr.price, 0);

        const combinedMessage = `
            Пришла корзина с id: ${cartId}\nКонтактная информация клиента: ${phone}📱\nEmail: ${email}✉️\nТовары в ней:\n${messages.map(msg => msg.message).join('')}\nОбщая стоимость корзины: ${totalCost}₽
        `;

        const response = await sendMessage(combinedMessage.trim());

        return response;
    }

    //* Функция для получения cartId
    static getCartId(){
        return 1;
    }

    //* Функция для создания предложения
    static async postOffer(offer){
        const message = `
            Поступило новое предложение с ${offer.title}.\nОписание: ${offer.description}.\nКонтактная информация: ${offer.phone}📱\nEmail: ${offer.email}📧
        `;

        const response = await sendMessage(message.trim());
        if(response.ok){
            alert('Предложение отправлено');
        } else {
            alert('Произошла ошибка при отправке. Попробуйте ещё раз!');
        }
    }
}
