import React from 'react'
import cl from "./ContactsPage.module.css"
import mail from "../../shared/assets/svgIcons/mail.svg"
import phone from "../../shared/assets/svgIcons/phone.svg"
import telegram from "../../shared/assets/svgIcons/telegram.svg"
import SocialBtn from '../../shared/modules/SocialBtn/SocialBtn'

function ContactsPage({className}) {
  
  return (
    <div className={`${cl.contactsPage} ${className}`}>
        <div className={cl.contactsPage__contacts}>
            <h3 className={cl.contactsPage__title}>Контакты</h3>
            <div className={cl.contactsPageContacts__text}>
            agrika0@mail.ru <br/>
            +7-960-346-89-98 Александр <br/>
            +7-905-325-92-62 Владимир <br/>
            Коноплянников Владимир Константинович
            </div>
            <div className={cl.contactsPage__text}>
            <span>Адрес:</span> 412531, Саратовская область, Петровский район,
            д. Гудошниково пром. зона фермерское хозяйство "Агрика"
            </div>
            <div className={cl.contactsPage__socials}>
              <SocialBtn icon={mail} name="email" link="mailto:agrika0@mail.ru"/>
              <SocialBtn icon={telegram} name="telegram" link="https://t.me/Ca6ok"/>
              <SocialBtn icon={phone} name="phone" link="tel:+79603468998"/>
            </div>
        </div>
        <div className={cl.contactsPage__details}>
            <div className={cl.contactsPage__title}>Реквизиты</div>  
            <div className={cl.contactsPage__text}>
            <span>ИП:</span> Коноплянников Владимир Константинович <br/>
            <span>Адрес:</span> 412531 Саратовская область, Петровский р-н, с. Гудошниково <br/>
            <span>ИНН:</span> 644400150031 <br/>
            <span>ОГРНИП:</span> 304640426600067 <br/>
            <span>Расчётный счет:</span> 40802810073000236801 <br/>
            <span>Банк:</span> ПРИВОЛЖСКИЙ Ф-Л ПАО "ПРОМСВЯЗЬБАНК" г НИЖНИЙ НОВГОРОД <br/>
            <span>Кор.счет:</span> 30101810700000000803 <br/>
            <span>БИК:</span> 042202803
            </div>
            <div className={cl.contactsPage__separator}/>
            <div className={cl.contactsPage__text}>
            <span>ИП:</span> Коноплянников Александр Владимирович <br/>
            <span>Адрес:</span> 412540 Саратовская область, Петровск ул. Московская д. 12 кв 79 <br/>
            <span>ИНН:</span> 644403622476 <br/>
            <span>ОГРНИП:</span> 310644401900010 <br/>
            <span>Расчётный счет:</span> 40802810193000967801 <br/>
            <span>Банк:</span> ПРИВОЛЖСКИЙ Ф-Л ПАО "ПРОМСВЯЗЬБАНК" г НИЖНИЙ НОВГОРОД <br/>
            <span>Кор.счет:</span> 30101810700000000803 <br/>
            <span>БИК:</span> 042202803
            </div>
        </div>
    </div>
  )
}

export default ContactsPage