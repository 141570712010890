import React from 'react'
import cl from "./BtnShop.module.css"
import shopIcon from '../../assets/svgIcons/mingcute_basket-line.svg'
import { Link, useLocation } from 'react-router-dom'

function BtnShop({children}) {
  const location = useLocation()

  return (
    <Link to='/cart' className={`${cl.btnShop} ${location.pathname === "/cart" ? cl.active : ""}`}>
        <img src={shopIcon} alt='shopBtn'/>
        <div className={cl.btnShop__text}>{children}</div>
    </Link>
  )
}

export default BtnShop